<template>
  <v-container>
    <v-card class="invoice-container" ref="templatePrintDelivery">
      <v-card-text>
        <!-- Header Section -->
        <v-row>
          <v-col
            cols="9"
            style="
              display: flex;
              align-items: start;
              justify-content: start;
              flex-direction: column;
            "
          >
            <h1>{{ $t("delivery_note") }}</h1>
            <h3 style="padding: 10px 0px">
              {{ $t("cashier") }} : {{ serving.user }}
            </h3>
            <h3 style="padding: 5px 0px">
              {{ $t("address") }} : {{ register.address }}
            </h3>
            <h3 style="padding: 5px 0px">
              {{ $t("phone") }} : {{ register.phone }}
            </h3>
          </v-col>
          <v-col
            cols="3"
            style="display: flex; align-items: center; justify-content: end"
          >
            <img
              :src="logoUrl"
              alt=""
              style="height: 150px; width: auto; border: 1px solid #ccc"
            />
          </v-col>
        </v-row>
        <!-- Item List -->
        <v-divider class="my-4"></v-divider>
        <v-simple-table>
          <tbody>
            <tr>
              <td class="text-left">{{ $t("customer") }}</td>
              <td>
                <v-text-field v-if="!isPrinted"
                  dense
                  outlined
                  v-model="customer.name"
                  hide-details
                  class="input-field"
                >
                </v-text-field>
                <h3 v-else class="input-field">{{ customer.name }}</h3>
              </td>
              <td class="text-left">{{ $t("phone_number") }}</td>
              <td>
                <v-text-field v-if="!isPrinted"
                  dense
                  outlined
                  v-model="customer.phone"
                  hide-details
                  class="input-field"
                >
                </v-text-field>
                <h3 v-else class="input-field">{{ customer.phone }}</h3>
              </td>
            </tr>
            <tr>
              <td class="text-left">{{ $t("address") }}</td>
              <td>
                <v-text-field v-if="!isPrinted"
                  dense
                  outlined
                  hide-details
                  v-model="customer.address"
                  class="input-field"
                  placeholder=""
                >
                </v-text-field>
                <h3 v-else class="input-field">{{ customer.address }}</h3>
              </td>
            </tr>
            <tr>
              <td class="text-left">{{ $t("price") }}</td>
              <td>
                <v-text-field v-if="!isPrinted"
                  dense
                  outlined
                  hide-details
                  v-model="serving.total"
                  class="input-field"
                  placeholder=""
                >
                </v-text-field>
                <h3 v-else class="input-field">{{ serving.total }}</h3>
              </td>
              <td class="text-left">{{ $t("delivery_fee") }}</td>
              <td>
                <v-text-field v-if="!isPrinted"
                  dense
                  outlined
                  hide-details
                  v-model="deliveryFee"
                  class="input-field"
                  placeholder=""
                >
                </v-text-field>
                <h3 v-else class="input-field">{{ deliveryFee }}</h3>
              </td>
            </tr>
            <tr>
              <td class="text-left">{{ $t("total_amount") }}</td>
              <td>
                <v-text-field v-if="!isPrinted"
                  dense
                  outlined
                  hide-details
                  v-model="totalAmount"
                  width="100%"
                  class="input-field"
                  placeholder=""
                >
                </v-text-field>
                <h3 v-else class="input-field">{{ totalAmount }}</h3>
              </td>
            </tr>
            <tr>
              <td class="text-left">{{ $t("payment_option") }}</td>
              <td>
                <v-text-field v-if="!isPrinted"
                  dense
                  outlined
                  width="100%"
                  v-model="deliveryOption"
                  hide-details
                  class="input-field"
                  placeholder=""
                >
                </v-text-field>
                <h3 v-else class="input-field">{{ deliveryOption }}</h3>
              </td>
              <td class="text-left">{{ $t("payment_status") }}:</td>
              <td>
                <v-text-field
                  v-if="!isPrinted"
                  dense
                  outlined
                  v-model="paymentStatus"
                  hide-details
                  class="input-field"
                >
                </v-text-field>
                <h3 v-else class="input-field">{{ paymentStatus }}</h3>
              </td>
            </tr>
            <tr>
              <td class="text-left">{{ $t("delivery_note") }}</td>
              <td>
                <v-text-field v-if="!isPrinted"
                  dense
                  outlined
                  v-model="deliveryNote"
                  hide-details
                  width="100%"
                  class="input-field"
                  placeholder=""
                >
                </v-text-field>
                <h3 v-else class="input-field">{{ deliveryNote }}</h3>
              </td>
            </tr>
          </tbody>
        </v-simple-table>

        <!-- Totals -->
        <v-divider class="my-4"></v-divider>
      </v-card-text>
    </v-card>
    <v-row>
      <v-col sm="12" cols="12">
        <div class="function_footer text-right">
          <v-btn
            color="primary"
            class="float-right white--text text-capitalize"
            @click="printHTML"
          >
            {{ $t("print") }}
          </v-btn>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import kendo from "@progress/kendo-ui";
import html2canvas from "html2canvas";
// import jsPDF from "jspdf";

const $ = kendo.jQuery;
const instituteId = localStorage.getItem("instituteId") || "";

export default {
  name: "PrintDelivery",
  props: { serving: {}, customer: {} },
  data() {
    return {
      
      subtotal: 400,
      tax: 40,
      total: 440,
      txns: [],
      isPrinted: false,
      decimal: 2,
      logoUrl: "",
      message: "",
      deliveryFee: 0,
      deliveryOption: "",
      paymentStatus: "",
      deliveryNote: "",
      isloadCreditTxn: false,
      creditTxns: [],
      activeStore: localStorage.getItem(instituteId + "commStore")
        ? JSON.parse(localStorage.getItem(instituteId + "commStore"))
        : {},
      bankWallets: [],
      setting: localStorage.getItem(instituteId + "commRSetting")
        ? JSON.parse(localStorage.getItem(instituteId + "commRSetting"))
        : {},
      register: localStorage.getItem(instituteId + "commRegister")
        ? JSON.parse(localStorage.getItem(instituteId + "commRegister"))
        : {},
    };
  },
  computed: {
    totalAmount() {
      if (this.deliveryFee === "") {
        return 0 + this.serving.total;
      }
      return parseFloat(this.deliveryFee) + this.serving.total;
    },
  },
  methods: {
    numberFormat(value) {
      return kendo.toString(parseFloat(value), `n${this.decimal}`);
    },
    dateTimeFormat(date) {
      return kendo.toString(new Date(date), "dd/MMM/yyyy HH:mm tt");
    },
    closeForm() {
      $("#closePrintSession").click();
    },
    printHTML() {
      this.isPrinted = true;
      setTimeout(() => {
        const input = this.$refs.templatePrintDelivery.$el;
        html2canvas(input).then((canvas) => {
          const imgData = canvas.toDataURL("image/png");

          // Create a new iframe
          const iframe = document.createElement("iframe");
          document.body.appendChild(iframe);

          // Write the image data and styles to the iframe
          iframe.contentWindow.document.open();
          iframe.contentWindow.document.write(`
            <html>
              <head>
                <title>Print</title>
                <style>
                  body {
                    margin: 0;
                  }
                  img {
                    width: 100%;
                    height: auto;
                  }
                </style>
              </head>
              <body>
                <img src="${imgData}" />
              </body>
            </html>
          `);
          iframe.contentWindow.document.close();
          this.isPrinted = false;
          // Wait for the content to load before printing
          iframe.onload = () => {
            iframe.contentWindow.focus();
            iframe.contentWindow.print();

            // Remove the iframe after printing
            setTimeout(() => {
              document.body.removeChild(iframe);
            }, 500);
          };
        });
      }, 1000);
    },
    fetchLogoUrl() {
      let r = this.register;
      this.institute = {
        vatTin: r.vat,
        companyAddress: r.address,
        companyPhone: r.phone,
      };
      window.console.log(this.institute, "institute");
      let hasLogo = false;
      if (r.hasOwnProperty("logo")) {
        if (Object.keys(r.logo).length > 0) {
          if (r.logo.hasOwnProperty("default")) {
            if (r.logo.default.thumb != "") {
              hasLogo = true;
            }
          }
        }
      }
      if (hasLogo) {
        this.logoUrl =
          "https://s3-ap-southeast-1.amazonaws.com/images.banhji/" +
          r.logo.default.thumb;
      }
    },
  },
  mounted() {
    this.fetchLogoUrl();
  },
};
</script>

<style scoped>
.invoice-container {
  width: 210mm;
  height: 148mm;
  padding: 16px;
  background-color: white;
  margin: auto;
  border: 1px solid #ccc;
}

.v-simple-table {
  width: 100%;
}

.input-field {
  width: 100%;
}

thead th {
  background-color: #f5f5f5;
  font-weight: bold;
}

tbody td {
  border-top: 1px solid #ddd;
}

tbody tr:nth-child(even) {
  background-color: #f9f9f9;
}

.text-left {
  text-align: left;
}

td[colspan="2"] {
  display: flex;
}

td[colspan="2"] .input-field {
  flex-grow: 1;
}

td[colspan="2"] .text-left {
  flex-shrink: 0;
  width: auto;
}
</style>